import { DollarOutlined, EyeOutlined, PercentageOutlined, PieChartOutlined, SearchOutlined, TransactionOutlined } from '@ant-design/icons';
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Alert, DatePicker, Divider, Empty, Select, Tooltip } from 'antd';
import { DomainContext, PadinationContext } from 'App';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { activeItem } from 'store/reducers/menu';
import bookingicon from "../../../assets/images/booking.svg";
import rewardicon from "../../../assets/images/rewards.svg"
import userIcon from '../../../assets/images/usericon.png';
import { ThreeDots } from 'react-loader-spinner';
import Loader from 'components/Loader';
import { useDispatch } from 'react-redux';
import { toast } from '../../../../node_modules/react-toastify/dist/index';
import { FormControlLabel, Pagination, Switch } from '../../../../node_modules/@mui/material/index';
import { BASE_URL } from 'Configration';
import { useNavigate, useParams } from '../../../../node_modules/react-router-dom/dist/index';

let keyword = '';
const { RangePicker } = DatePicker;

const RewardPage = () => {
    const token = JSON.parse(localStorage.getItem('adAnimaLogin'));
    const [inputdata, setInputdata] = useState({
        minamount: '',
        maxamount: '',
        checked: '',
        id:""
    });
    const [breadcrumb, setbreadcrumb] = useState(JSON.parse(sessionStorage.getItem('breadcrumb')));
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [openAdd, setOpenAdd] = React.useState(false);
    const [isLoader, setIsLoader] = useState(true);
    const [isEdit, setIsEdit] = useState(false);

    const handleOpen = () => setOpenAdd(true);
    const [file, setFile] = useState(null);
    const handleClose = () => {
        setIsEdit(false);
        setOpenAdd(false);
        setFile('');
    };
    const { number } = useParams();
    const [totalPage, settotalPage] = useState(1);
    const [controller, setController] = useState(null);
    // useEffect(()=>{if(!/^\d+$/.test(number)) {
    //         console.log(number);

    //         return <Navigate to="/not-found" replace />;
    //     }},[number])
    const [transNumberData, setTransNumberData] = useState();
    const [userdata, setuserdata] = useState([]);
    const [totaldata, setTotaldata] = useState(null); //
    const [selectedDate, setSelectedDate] = useState([]);
    const [searchchange, setsearchchange] = useState('');
    const [popup, setpopup] = useState({ show: false, message: '', success: true });
    const [stateJson, setStateJson] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('All');
    const [selectedState, setSelectedState] = useState();
    const getalldata = () => {
        if (token?.is_login && token?.is_login == true) {
            dispatch(activeItem({ openItem: ['util-rewards'] }));
            const userList = new FormData();

            userList.append('search', searchchange);
            {
                Number(number) ? userList.append('page', Number(number)) : userList.append('page', Number(1));
            }
            userList.append('limit', 10);
            if (controller) {
                controller.abort();
            }
            const newController = new AbortController();
            setController(newController);

            try {
                fetch(`${BASE_URL}/reward/reward_list`, {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${token.token}`
                    },
                    body: userList,
                    signal: newController.signal
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.success) {
                            setIsLoader(false);
                            setTotaldata(data.total_number_of_data);
                            // setTransNumberData(data?.total_number_of_data);
                            setuserdata(data.data);
                            const totalNumberOfData = data?.total_number_of_data?.totalCount || 0;
                            const totalPages = Math.ceil(totalNumberOfData / 10);
                            settotalPage(totalPages);
                        } else if (data.statuscode == 101) {
                            localStorage.clear();
                            toast.error(data?.message);
                            window.location.reload();
                            // navigate('/login');
                        }
                    });
            } catch (err) {
                setIsLoader(false);
                console.log(err);
            }
        } else {
            setIsLoader(false);
            navigate('/login');
        }
    };
    const getrewarddata = () => {
        if (token?.is_login && token?.is_login == true) {
            dispatch(activeItem({ openItem: ['util-rewards'] }));
    
          
            if (controller) {
                controller.abort();
            }
            const newController = new AbortController();
            setController(newController);

            try {
                fetch(`${BASE_URL}/reward/get_reward`, {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${token.token}`
                    },
                    // body: "userList",
                    signal: newController.signal
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.success) {
                            console.log(data.data);
                            
                            setInputdata({
                                ...inputdata,
                                minamount:data.data.min_point,
                                maxamount:data.data.max_point,
                                id:data.data._id,
                                checked:data.data?.is_active
                            })
                            setSelectedDate([ dayjs(data.data.start_date).startOf("day") ,dayjs(data.data.end_date).endOf("day") ])
                          
                        } else if (data.statuscode == 101) {
                            localStorage.clear();
                            toast.error(data?.message);
                            window.location.reload();
                            // navigate('/login');
                        }
                    });
            } catch (err) {
                setIsLoader(false);
                console.log(err);
            }
        } else {
            setIsLoader(false);
            navigate('/login');
        }
    };

    
    useEffect(() => {
        if (popup.show) {
            setTimeout(() => {
                setpopup(false);
            }, 3000);
        }
    }, [popup]);
    useEffect(() => {
        getalldata();

        return () => {
            if (controller) {
                controller.abort();
            }
        };
    }, [number, searchchange, selectedDate]);
useEffect(()=>{
    getrewarddata()
},[])
    useEffect(() => {
        let bread = [];
        let prepath = false;
        for (var i = 0; i < breadcrumb?.length; i++) {
            bread.push(breadcrumb[i]);
            if (breadcrumb[i].url == window.location.pathname) {
                prepath = true;
                break;
            }
        }
        if (prepath == false) {
            setbreadcrumb(JSON.parse(sessionStorage.getItem('breadcrumb')));
        } else {
            setbreadcrumb(bread);
            sessionStorage.setItem('breadcrumb', JSON.stringify(bread));
        }
    }, []);

    const rangePresets = [
        {
            label: 'Last 30 Days',
            value: [dayjs().add(-30, 'd'), dayjs()]
        }
    ];

    const onRangeChange = (dates, dateStrings) => {
        setSelectedDate(dates);
    };
    const disabledDate = (current) => {
        return current && current < dayjs().startOf("day"); // Disable past dates
      };
    function formatNumber(number) {
        if (number >= 1000000) {
            return (number / 1000000).toFixed(2) + 'M';
        } else if (number >= 1000) {
            return (number / 1000).toFixed(2) + 'K';
        } else {
            return number?.toFixed(2);
        }
    }



  
    // const handleExport = (startDate, endDate, country, state) => {
    //     const userList = new FormData();
    //     userList.append('search', searchchange);
    //     if (selectedDate != null) {
    //         userList.append('fromDate', selectedDate[0]?.startOf('date')?.toISOString());
    //         userList.append('toDate', selectedDate[1]?.endOf('date')?.toISOString());
    //     }

    //     if (selectedState != 'All') {
    //         userList.append('state', selectedState);
    //     }
    //     if (selectedCountry != 'All') {
    //         userList.append('country', selectedCountry);
    //     }

    //     try {
    //         fetch(`${BASE_URL}/user_details/export_payment_list`, {
    //             method: 'POST',
    //             headers: {
    //                 Authorization: `Bearer ${token.token}`
    //             },
    //             body: userList
    //         })
    //             .then((response) => response.json())
    //             .then((data) => {
    //                 const url = data.data;
    //                 const link = document.createElement('a');
    //                 link.href = url;
    //                 link.download = 'downloaded-file.csv';
    //                 document.body.appendChild(link);

    //                 link.click();

    //                 document.body.removeChild(link);
    //             });
    //     } catch (err) {
    //         console.log(err);
    //     }
    // };
   
 
    const handleChange = (data,e) => {
        setInputdata(prevState => ({
            ...prevState,
            checked: e.target.checked
          }));
        const formdata=new FormData();
        formdata.append('reward_id',data);
        formdata.append('is_active', e.target.checked);
        try {
            fetch(`${BASE_URL}/reward/active_deactive_reward`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token.token}`
                },
                body: formdata
            })
                .then((response) => response.json())
                .then((data) => {
                    if(data.success) {
                       
                      
                 toast.success(data.message);}
                });
        } catch (err) {
            console.log(err);
        }
    };
    const [error, setError] = useState({
        minerror: "",
        maxerror: "",
        dateerror:""
  
    });        // Convert values to numbers for reliable comparisons
      
        // Check if minamount is greater than maxamount
        const validation = () => {
            let errors= {};
        
            // Ensure minAmount and maxAmount are numbers
            const minAmount = Number(inputdata.minamount);
            const maxAmount = Number(inputdata.maxamount);
        
            // Check if minAmount or maxAmount is empty
            if (!inputdata.minamount) {
                errors.minerror = "Minimum amount is required.";
            }
            if (!inputdata.maxamount) {
                errors.maxerror = "Maximum amount is required.";
            }
        
            // Check if minAmount exceeds 100,000
            if (minAmount <= 0 || minAmount > 100000) {
                errors.minerror = "Minimum amount must be greater than 0 and less than or equal to 100,000.";
            }
            if (maxAmount <= 0 || maxAmount > 100000) {
                errors.maxerror = "Maximum amount must be greater than 0 and less than or equal to 100,000.";
            }
        
            // Check if minAmount is greater than maxAmount
            if (minAmount > maxAmount) {
                errors.minerror = "Minimum amount cannot be greater than maximum amount.";
            }
        
            // Check if minAmount and maxAmount are the same
            if (minAmount === maxAmount) {
                errors.maxerror = "Maximum amount cannot be the same as minimum amount.";
            }
        
            // Check if date range is empty
            if (!selectedDate || !Array.isArray(selectedDate) || selectedDate.length === 0) {
                errors.dateerror = "Please select a date range.";
            }
        
          
            if (Object.keys(errors).length > 0) {
                setError({
                    ...error,
                    ...errors,
                });
                return;
            }
        
      
            setError({
                minerror: "",
                maxerror: "",
                dateerror: "",
            });
        
            editrewards(); // Proceed with the function
        };
        
        
        
   
    // const validation = (field, value) => {
    //     const updatedInputData = { ...inputdata, [field]: value };
    
    //     if (Number(updatedInputData.minamount) > Number(updatedInputData.maxamount)) {
    //         setError({ ...error, minamount: "Value cannot be greater than maximum amount." });
    //     } else if (Number(updatedInputData.maxamount) < Number(updatedInputData.minamount)) {
    //         setError({ ...error, maxamount: "Maximum amount must be greater than Minimum amount." });
    //     } else {
    //         setError({}); // Clear error if validation passes
    //         setInputdata(updatedInputData); // Update the state with the new value
    
         
    //         editrewards();
    //     }
    // };
    console.log(inputdata);
    
const editrewards=()=>{
   console.log(error);
   
   
        const formdata=new FormData();
        formdata.append('reward_id',inputdata.id);
        formdata.append('start_date', selectedDate[0]?.startOf('date')?.toISOString());
        formdata.append('end_date', selectedDate[1]?.endOf('date')?.toISOString());
        formdata.append('min_point', inputdata.minamount);
        formdata.append('max_point', inputdata.maxamount);
    
        try {
            fetch(`${BASE_URL}/reward/edit_reward`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token.token}`
                },
                body: formdata
            })
                .then((response) => response.json())
                .then((data) => {
                    if(data.success) {
                        setInputdata({
                            ...inputdata,
                            minamount:data.data.min_point,
                            maxamount:data.data.max_point,
                            id:data.data._id,
                           checked:data.data?.is_active
                        })
                        setSelectedDate([ dayjs(data.data.start_date).startOf("day") ,dayjs(data.data.end_date).endOf("day") ])
                        // getrewarddata()
                 toast.success(data.message);}
                });
        } catch (err) {
            console.log(err);
        }
      

   
}
    return (
        <>
            <div className="breadcrumb d-flex align-items-center">
                <p style={{ color: '#000000' }}>{'Rewards'}</p>
            </div>

            <div className="col-lg-12 d-flex flex-wrap align-items-center mb-1 g-3">
                <div className="col-lg-6 p-2 position-relative flex-lg-grow-0 flex-grow-1">
                    <input
                        className="search-input"
                        placeholder="Search here"
                        value={searchchange}
                        onChange={(e) => {
                            setsearchchange(e.target.value);

                            navigate(`/rewards/1`);
                        }}
                    />
                    <div className="search-icon">
                        <SearchOutlined />
                    </div>
                </div>
            </div>
         
            <div
                className=""
                style={{
                    // border: '1px solid #d6d6d6',
                    // background: 'white',
                    borderRadius: '5px',
                    padding: '22px',
                    marginBottom: '20px'
                }}
            >
                <div className="inner-gred box_shadow row">
                  

                   
                    <div className="col-6 col-lg-2 ">
                        <div>
                            <p className="t-14-500 text-black m-0">Minimum Amount</p>
                            <div className="group-input mt-2 p-15 clearfix">
                                <input
                                    required
                                    style={{ color: '#000000' }}
                                    type="number"
                                    max={100000}
                                    onChange={(e) => {
                                        const val =e.target.value;
                                        console.log(val);
                                        
                                      
                                        
                                      
                                        setInputdata((pre) => ({
                                            ...pre,
                                            minamount: val
                                        }));
                                   
                                    }}
                                    // onChange={(e) => validation('minamount', e.target.value)}
                                    placeholder={'Enter Minimum Amout'}
                                    value={inputdata.minamount}
                                />
                            </div>
                        </div>
                            <p className="t-13-500 text-danger">{error?.minerror}</p>
                    </div>
                    <div className="col-6 col-lg-2 ">
                        <div>
                            <p className="t-14-500 text-black m-0">Maximum Amount</p>
                            <div className="group-input mt-2 p-15 clearfix">
                                <input
                                    required
                                    style={{ color: '#000000' }}
                                    type="number"
                                    placeholder={'Enter Maximum Amout'}
                                    value={inputdata.maxamount}
                                    max={100000}
                                    onChange={(e) => {
                                        const val =e.target.value;
                                        console.log(val);
                                        
                                      
                        
                                            setInputdata((pre) => ({
                                                ...pre,
                                                maxamount: val
                                            }));
                                          
                                        }}
                                        // onChange={(e) => validation('maxamount', e.target.value)}
                                 
                                />
                            </div>
                        </div>
                            <p className="t-13-500 text-danger">{error?.maxerror}</p>
                    </div>
                    <div className="col-6 col-lg-4">
                        {/* <div className="ms-auto me-2"> */}
                        <p className="t-14-500 text-black m-0">Select Date</p>
                        <div className="group-inputd   " style={{ marginTop: '9px' }}>
                            <RangePicker style={{ width: '100%', height: '46px' }}   disabledDate={disabledDate}  presets={rangePresets} onChange={onRangeChange} value={selectedDate}/>
                            {/* </div> */}
                        </div>
                        <p className="t-13-500 text-danger">{error?.dateerror}</p>
                    </div> 
                   
                        
                    <div className=" d-flex justify-content-between align-items-start col-6 col-lg-4" style={{ marginTop: '32px' }}>
                        <div className="col-12 col-lg-5 ">
                            <Button
                                size="large"
                             
                                style={{ opacity: userdata?.length <= 0 ? '0.5' : '1', height: '49px' ,width:"100%"}}
                                disabled={userdata.length <= 0 ? true : false}
                                onClick={()=>{validation()}}
                                className="d-flex align-items-center  bg-white add-cattegory-button"
                                type="primary"
                                ghost
                            >
                                <span className="ms-1">Add Reward</span>
                            </Button>
                        </div>
                        <div className=" d-flex col-12 col-lg-7 justify-content-end">
                            <div className=" me-2 d-flex align-items-center "    style={{ marginright: '0px',    marginTop: "0px"}}>Enable Reward?</div>
                            <FormControlLabel
                                style={{ marginright: '0px',    marginTop: "0px"}}
                                control={<Switch checked={inputdata.checked} onChange={(e)=>{handleChange(inputdata.id,e)}} />}
                            />
                        </div>{' '}

                    </div>
                 
                   
                </div>
            </div>
            {/* </div> */}

            <div className="mx-2 mb-4">
                <Grid container spacing={2}>
                    <Grid item xs={6} lg={6} xl={3}>
                        <div
                            className="text-start p-4 d-flex align-items-center justify-content-between"
                            style={{ border: '1px solid #d6d6d6', borderRadius: '5px', background: 'white' }}
                        >
                            <div>
                                <p className="m-0" style={{ color: '#3498db', fontSize: '21px' }}>
                                    {totaldata?.totalReward ?? 0}
                                </p>
                                <p className="m-0" style={{ color: '#9c9c9c' }}>
                                    Total Earn Rewards
                                </p>
                            </div>
                            <img src={rewardicon} alt='icons'></img>
                            {/* <TransactionOutlined style={{ fontSize: '30px', color: '#3498db' }} /> */}
                        </div>
                    </Grid>
                    <Grid item xs={6} lg={6} xl={3}>
                        <div
                            className="text-start p-4 d-flex align-items-center justify-content-between"
                            style={{ border: '1px solid #d6d6d6', borderRadius: '5px', background: 'white' }}
                        >
                            <div>
                                <p
                                    className="m-0"
                                    style={{
                                        color: '#2ecc71',
                                        fontSize: '21px'
                                    }}
                                >
                                    {totaldata?.totalBooking ?? 0}
                                </p>
                                <p className="m-0" style={{ color: '#9c9c9c' }}>
                                    Total Booking
                                </p>
                            </div>
                            <img src={bookingicon} alt='icons'></img>
                            {/* <PercentageOutlined style={{ fontSize: '30px', color: '#2ecc71' }} /> */}
                        </div>
                    </Grid>
                    {/* <Grid item xs={6} lg={6} xl={3}>
                        <div
                            className="text-start p-4 d-flex align-items-center justify-content-between"
                            style={{ border: '1px solid #d6d6d6', borderRadius: '5px', background: 'white' }}
                        >
                            <div>
                                <p
                                    className="m-0"
                                    style={{
                                        color: '#f39c12',
                                        fontSize: '21px'
                                    }}
                                >
                                    $ {formatNumber(Number(transNumberData?.totalFeesAmount ?? 0))}
                                </p>
                                <p className="m-0" style={{ color: '#9c9c9c' }}>
                                    Total Collected Fees
                                </p>
                            </div>
                            <PieChartOutlined style={{ fontSize: '30px', color: '#f39c12' }} />
                        </div>
                    </Grid>
                    <Grid item xs={6} lg={6} xl={3}>
                        <div
                            className="text-start p-4 d-flex align-items-center justify-content-between"
                            style={{ border: '1px solid #d6d6d6', borderRadius: '5px', background: 'white' }}
                        >
                            <div>
                                <p
                                    className="m-0"
                                    style={{
                                        color: '#e74c3c',
                                        fontSize: '21px'
                                    }}
                                >
                                    $ {formatNumber(Number(transNumberData?.totalPaymentAmount ?? 0))}
                                </p>
                                <p className="m-0" style={{ color: '#9c9c9c' }}>
                                    Total Transaction Amount
                                </p>
                            </div>
                            <DollarOutlined style={{ fontSize: '30px', color: '#e74c3c' }} />
                        </div>
                    </Grid> */}
                </Grid>
            </div>

            <Divider orientation="left" className="my-2">
               Rewards
            </Divider>
            {isLoader ? (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '10%'
                    }}
                >
                    <ThreeDots
                        visible={true}
                        height="80"
                        width="80"
                        color="#0dbcec"
                        radius="9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{ color: 'linear-gradient(180deg, #0dbcec, #ffc056)}' }}
                        wrapperClass=""
                    />{' '}
                </div>
            ) : (
                <div className="p-2">
                    {userdata?.length > 0 ? (
                        <TableContainer component={Paper} className="mb-4">
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow className="bg-light-gray">
                                        <TableCell className="bh-line text-center">User</TableCell>

                                        <TableCell className="bh-line text-center">Reward Amount</TableCell>

                                        <TableCell className="bh-line text-center">Reward Type</TableCell>
                                        <TableCell className="bh-line text-center">Service & Event Name</TableCell>
                                        <TableCell className="bh-line text-center">Create date</TableCell>

                                        {/* <TableCell className="bh-line text-center">Action</TableCell> */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {userdata?.length > 0 &&
                                        userdata?.map((data, i) => (
                                            <TableRow
                                                key={data._id}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 }, height: '100px' }}
                                            >
                                                <TableCell
                                                    className="br-line"
                                                    // onClick={() => {
                                                    //     navigate(`/providerdetails/${data?.payment_from_user_id}`);
                                                    // }}
                                                    style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
                                                >
                                                    <img
                                                        src={
                                                            data.user_profile_picture
                                                                ? data.user_profile_picture
                                                                : data.user_provider_banner_image? data.user_provider_banner_image:userIcon
                                                        }
                                                        alt=""
                                                        className="tran_user_img"
                                                    />{' '}
                                                    {data.user_name || '-'}
                                                </TableCell>
                                                <TableCell className="br-line text-center">{data.total_paid_amount ?? '-'}</TableCell>
                                                <TableCell className="br-line text-center">{data.reward_type ?? '-'}</TableCell>
                                                <TableCell className="br-line text-center">{data.reward_type === "service"?data.service_name :data.event_name}</TableCell>

                                                <TableCell className="br-line text-center" style={{ whiteSpace: 'nowrap' }}>
                                                    {new Date(data.createdAt).toDateString() ?? '-'}
                                                </TableCell>
                                                {/* <TableCell align="center">
                                                    <div className="d-flex justify-content-center">
                                                        <Tooltip placement="top" title={'View Transaction'}>
                                                            <Link
                                                                to={`/transactions-details/${data?._id}`}
                                                                //  onClick={() => breadcrumbManager(data?.name, `/pre-polutation/${data?._id}`)}
                                                            >
                                                                <div
                                                                    className="action-button bg-white"
                                                                    style={{ border: '1px solid #1677ff', color: '#1677ff' }}
                                                                >
                                                                    <EyeOutlined />
                                                                </div>
                                                            </Link>
                                                        </Tooltip>
                                                    </div>
                                                </TableCell> */}
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <Empty />
                    )}
                </div>
            )}
            {totalPage > 1 && (
                <>
                    <Pagination
                        count={totalPage}
                        defaultPage={1}
                        color="primary"
                        className="my-5 d-flex justify-content-center"
                        page={parseInt(number) || 1}
                        onChange={(event, number) => {
                            navigate(`/rewards/${number}`);
                        }}
                    />
                </>
            )}

            {popup.show && (
                <Alert
                    message={popup?.message}
                    type={popup?.success ? 'success' : 'error'}
                    showIcon
                    style={{ position: 'fixed', right: '15px', bottom: '30px' }}
                />
            )}
            {isLoader && <Loader />}
        </>
    );
};

export default RewardPage;
