import React, { useEffect, useState } from 'react';
import { Button, Empty, Input, Modal, Select } from 'antd';
import { Link } from 'react-router-dom';
import { ThreeDots } from 'react-loader-spinner';
// assets
import { EyeOutlined, LogoutOutlined, UnlockOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { DomainContext } from 'App';
import { BASE_URL } from 'Configration';
import { FormHelperText, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Stack } from '@mui/material';
import { toast } from '../../../../node_modules/react-toastify/dist/index';
import { useNavigate, useParams } from '../../../../node_modules/react-router-dom/dist/index';
function AddMember() {
    const { id } = useParams();
    console.log(id);

    const [isLoading, setIs_loading] = useState(true);
    const [breadcrumb, setbreadcrumb] = useState(JSON.parse(sessionStorage.getItem('breadcrumb')));
    const [editId, seteditId] = useState('');
    const token = JSON.parse(localStorage.getItem('adAnimaLogin'));
    const [editMode, seteditMode] = useState(false);
    const [inputvalue, setinputvalue] = useState({
        fullname: '',
        email: '',
        password: '',
        access: {
            dashboard: false,
            users: false,
            providers: false,
            events: false,
            services: false,
            sales_tax: false,
            transactions: false,
            support: false,
            referral_codes: false,
            discount_codes: false,
            pre_population_events: false,
            report: false,
            pre_population: false,
            banner_image: false,
            rewards: false,
        }
        // access: [
        //     {
        //         is_allowed: false,
        //         access: "1",
        //         label: 'Dashboard'
        //     },
        //     {
        //         is_allowed: false,
        //         access: "2",
        //         label: 'User'
        //     },
        //     {
        //         is_allowed: false,
        //         access: "3",
        //         label: 'Providers'
        //     },
        //     {
        //         is_allowed: false,
        //         access: "4",
        //         label: 'Events'
        //     },
        //     {
        //         is_allowed: false,
        //         access: "5",
        //         label: 'Sales Tax'
        //     },
        //     {
        //         is_allowed: false,
        //         access: "6",
        //         label: 'Transactions'
        //     },
        //     {
        //         is_allowed: false,
        //         access: "7",
        //         label: 'Support'
        //     },
        //     {
        //         is_allowed: false,
        //         access: "8",
        //         label: 'Referral-Codes'
        //     },
        //     {
        //         is_allowed: false,
        //         access: "9",
        //         label: 'Discount Codes'
        //     },

        //     {
        //         is_allowed: false,
        //         access: "10",
        //         label: 'Pre-Population Events'
        //     },
        //     {
        //         is_allowed: false,
        //         access: "11",
        //         label: 'Report'
        //     },
        //     {
        //         is_allowed: false,
        //         access: "12",
        //         label: 'Pre-Population'
        //     },
        //     {
        //         is_allowed: false,
        //         access: "13",
        //         label: 'Banner Image'
        //     }
        // ]
    });
    const [searchInput, setsearchInput] = useState('');
    const [showPassword, setShowPassword] = useState({
        newpass: false
    });
    const navigate = useNavigate();
    const handleClickShowPassword = (field) => {
        setShowPassword((prev) => ({ ...prev, [field]: !prev[field] }));
    };
    const [passerr, setpasserr] = useState({ newpass: '' ,check:""});

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const [roleData, setroleData] = useState([]);
    const [load, setload] = useState(true);
    const [IsLoader, setIsLoader] = useState(false);
    const breadcrumbManager = (a, b) => {
        let bread = [];
        let prepath = false;
        for (var i = 0; i < breadcrumb?.length; i++) {
            bread.push(breadcrumb[i]);
            if (breadcrumb[i].url == b) {
                prepath = true;
                break;
            }
        }
        if (prepath == false) {
            bread.push({ name: a, url: b });
        }

        sessionStorage.setItem('breadcrumb', JSON.stringify(bread));
    };
    useEffect(() => {
        const formdata = new FormData();
        if (id) {
            setIsLoader(true);
            formdata.append('user_id', id);
            try {
                fetch(`${BASE_URL}/admin_role/get_member`, {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${token.token}`
                    },
                    body: formdata
                })
                    .then((response) => response.json())
                    .then((data) => {
                        // setload(false);
                        if (data.success) {
                          setIsLoader(false);
                            //   toast.success(data?.message);
                            setinputvalue({
                                fullname: data?.data?.name,
                                email: data?.data?.email_address,
                                password: data?.data?.password,
                                access: data?.data?.roles
                            });
                        } else {
                            toast.error(data?.message);
                        }
                    });
            } catch (err) {
                console.log(err);
            }
        }
    }, [id]);
    console.log(inputvalue);

    const submitRole = () => {
        if (token?.is_login && token?.is_login == true) {
            setload(true);
            const employeeData = new FormData();
            if (id) {
                employeeData.append('user_id', id);
            }
            employeeData.append('name', inputvalue.fullname);
            employeeData.append('email_address', inputvalue.email);
            employeeData.append('password', inputvalue.password);
            employeeData.append('roles', JSON.stringify(inputvalue.access));

            if (id) {
                try {
                    fetch(`${BASE_URL}/admin_role/edit_member`, {
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${token.token}`
                        },
                        body: employeeData
                    })
                        .then((response) => response.json())
                        .then((data) => {
                           
                            if (data.success) {
                                setIsLoader(false);
                                toast.success(data?.message);
                                navigate(`/roles`);
                            } else {
                                toast.error(data?.message);
                            }
                        });
                } catch (err) {
                    console.log(err);
                }
            } else {
                try {
                    fetch(`${BASE_URL}/admin_role/add_member`, {
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${token.token}`
                        },
                        body: employeeData
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            setload(false);
                            if (data.success) {
                                toast.success(data?.message);
                                setroleData([...roleData, data.data[0]]);
                                navigate(`/roles`);
                            } else {
                                toast.error(data?.message);
                            }
                        });
                } catch (err) {
                    console.log(err);
                }
            }
        } else {
            navigate('/login');
        }
    };
    const handleValidation = () => {
      const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.#$!%*?&])[A-Za-z\d@.#$!%*?&]{8,15}$/;
  
      // Checkbox validation: Check if at least one checkbox is selected
      const hasCheckboxSelected = Object.values(inputvalue.access).some((value) => value);
  
      if (!hasCheckboxSelected) {
          setpasserr({ check: 'Please select at least one option.' });
          return;
      }
  
      // Password validation
      if (inputvalue.password === '') {
          setpasserr({ newpass: 'Please enter Password' });
      } else if (!passwordRegex.test(inputvalue.password)) {
          setpasserr({
              newpass:
                  'Password must be 8-15 characters and contain at least one uppercase letter, one lowercase letter, one digit, and one special character (@.#$!%*?&)',
          });
      } else {
          setpasserr({ newpass: '' });
          // Both validations passed, proceed with the submit
          submitRole();
      }
  };
  
  
    return (
        <div>
            <div className="breadcrumb d-flex align-items-center">
                {breadcrumb?.map((e, i) => {
                    return (
                        <>
                            {i != breadcrumb?.length - 1 ? (
                                <>
                                    <Link
                                        key={e.url}
                                        to={e.url}
                                        onClick={() => {
                                            breadcrumbManager(e.name, e.url);
                                            setload(true);
                                        }}
                                    >
                                        <p style={{ color: '#abaaaa' }}>{e.name}</p>
                                    </Link>
                                    <p className="mx-2">/</p>
                                </>
                            ) : (
                                <Link key={e.url} to={e.url} onClick={() => breadcrumbManager(e.name, e.url)}>
                                    <p style={{ color: '#000000' }}>{e.name}</p>
                                </Link>
                            )}
                        </>
                    );
                })}
            </div>
            <div className="p-3 inner-gred box_shadow" style={{ borderRadius: '10px' }}>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        // submitRole();
                    }}
                >
                   {IsLoader ? (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    marginTop: '10%'
                                                }}
                                            >
                                                <ThreeDots
                                                    visible={true}
                                                    height="80"
                                                    width="80"
                                                    color="#0dbcec"
                                                    radius="9"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{ color: 'linear-gradient(180deg, #0dbcec, #ffc056)}' }}
                                                    wrapperClass=""
                                                />{' '}
                                            </div>
                                        ) : <>
                    <div class="padding ">
                        <div class="row">
                            <div
                                className="col-12 mb-4 "
                                style={{
                                    fontSize: '20px',
                                    fontWeight: '600',
                                    lineHeight: '27.24px'
                                }}
                            >
                                {id ? 'Edit Member' : 'Add Member'}
                            </div>

                            <div class="col-md-12 col-sm-12  position-relative pl-0 d-flex">
                                <div className=" col-12">
                                    <div className="col-12 row">
                                        <div className="col-md-6 col-sm-12">
                                            <p className="t-14-500 text-black m-0">Name</p>
                                            <div className="group-input mt-2  p-15 clearfix">
                                                <input
                                                    required
                                                    style={{ color: '#000000' }}
                                                    type="text"
                                                    onChange={(e) => {
                                                        if (e?.target?.value?.length <= 30) {
                                                            setinputvalue((pre) => ({
                                                                ...pre,
                                                                fullname: e.target.value
                                                            }));
                                                        }
                                                    }}
                                                    placeholder={'Enter Name '}
                                                    value={inputvalue?.fullname}
                                                />
                                            </div>
                                            {/* <p className="t-13-500 text-danger">{error?.name}</p> */}
                                        </div>

                                        <div className="col-md-6 col-sm-12 position-relative pr-0">
                                            <p className="t-14-500 text-black m-0">Email</p>
                                            <div className="group-input mt-2 p-15 clearfix">
                                                <input
                                                    required
                                                    style={{ color: '#000000' }}
                                                    type="email"
                                                    onChange={(e) => {
                                                        setinputvalue((pre) => ({
                                                            ...pre,
                                                            email: e.target.value
                                                        }));
                                                    }}
                                                    placeholder={'Enter Email'}
                                                    value={inputvalue?.email}
                                                />

                                                {/* <p className="t-13-500 text-danger">{error?.about}</p> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 row">
                                        <div className="col-md-6 mt-3 col-sm-12 position-relative pr-0">
                                            <p className="t-14-500 text-black m-0">Password</p>
                                            <div className="group-input mt-2 p-15 clearfix">
                                                <OutlinedInput
                                                    fullWidth
                                                    // error={Boolean(touched.password && errors.password)}
                                                    id="new-password"
                                                    type={showPassword.newpass ? 'text' : 'password'}
                                                    name="newpass"
                                                    value={inputvalue.password}
                                                    onChange={(e) => setinputvalue({ ...inputvalue, password: e.target.value })}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                className="passwordc"
                                                                onClick={() => handleClickShowPassword('newpass')}
                                                                onMouseDown={handleMouseDownPassword}
                                                                edge="end"
                                                                style={{ color: '#666666', backgroundColor: 'white' }}
                                                                size="large"
                                                            >
                                                                {showPassword.newpass ? (
                                                                    <EyeOutlined className="" />
                                                                ) : (
                                                                    <EyeInvisibleOutlined className="" />
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    placeholder="New Password"
                                                />
                                            </div>
                                            <p className="t-13-500 text-danger">{passerr.newpass}</p>
                                            <div className="col-md-6 mt-3 col-sm-12 position-relative pr-0"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <p className="t-14-800 text-black mb-3">Can Access..</p>
                    <div class="padding">
                        <div class="row">
                            {Object.entries(inputvalue.access).map(([key, value]) => {
                                return (
                                    <div className="col-xxl-2 col-xl-3 mb-2" key={key}>
                                        <div className="sub-admin d-flex width-box">
                                            <div className="margin d-flex check">
                                                <label className="gred-checkbox">
                                                    <input
                                                        type="checkbox"
                                                        checked={value} // assuming 'value' represents whether the access is allowed
                                                        onChange={(e) => {
                                                            setinputvalue({
                                                                ...inputvalue,
                                                                access: {
                                                                    ...inputvalue.access,
                                                                    [key]: e.target.checked // update the access key with the checkbox value
                                                                }
                                                            });
                                                        }}
                                                      
                                                    />
                                                    <span className="checkmark"> </span>
                                                </label>
                                                {/* <span style={{ fontWeight: '600', fontSize: '15px' }}>
                                                    {key.charAt(0).toUpperCase() + key.slice(1)}
                                                </span> */}
                                                <span style={{ fontWeight: '600', fontSize: '15px' }}>
  {key.replace(/_/g, ' ').charAt(0).toUpperCase() + key.replace(/_/g, ' ').slice(1)}
</span>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                              <p className="t-13-500 text-danger">{passerr.check}</p>
                        </div>
                    </div>
                    <div className="d-flex mt-4">
                        {inputvalue.name !== null ? (
                            <button
                                type="submit"
                                className="btn py-2 px-3 px-sm-5"
                                style={{
                                    backgroundColor: '#1686f1',
                                    color: 'white',
                                    marginLeft: 'auto'
                                }}
                                onClick={handleValidation}
                            >
                                {id ? 'Save' : 'Create'}
                            </button>
                        ) : (
                            <button
                                type="button"
                                className="btn py-2 px-3 px-sm-5"
                                style={{
                                    backgroundColor: '#b5b5b5',
                                    color: 'white',
                                    marginLeft: 'auto'
                                }}
                            >
                                Create
                            </button>
                        )}

                        <button
                            type="button"
                            className="btn py-2 px-3 px-sm-5 ms-3"
                            style={{
                                backgroundColor: '#C5D9E5',
                                color: '#000'
                            }}
                            onClick={() => {
                                // setrolModal(false);
                                // emptyField();
                                setinputvalue('');
                                navigate('/roles');
                            }}
                        >
                            Cancel
                        </button>
                    </div></>}
                </form>
            </div>
        </div>
    );
}

export default AddMember;
